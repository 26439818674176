// src/Chat.js

import React, { useState, useEffect } from 'react';
import { MessageList, Input, SystemMessage } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import axios from 'axios';
import './Chat.css';
// Import Material UI components
import { FormControl, InputLabel, Select, MenuItem, Chip, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const typingResponses = [
  "AI is thinking...",
  "Processing your request...",
  "Generating a response...",
  "Analyzing the information...",
  "Crafting a reply...",
  "Computing an answer...",
  "Formulating thoughts...",
  "Pondering your message...",
];

// Add this line near the top of the file
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

// Create a theme (optional, but recommended for consistent styling)
const theme = createTheme();

const Chat = () => {
  const [messages, setMessages] = useState([]);
  // Change this line to support multiple selected studies
  const [selectedStudyIds, setSelectedStudyIds] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [typingMessage, setTypingMessage] = useState();
  const [typingInterval, setTypingInterval] = useState(null);
  const [studies, setStudies] = useState([]);

  useEffect(() => {
    fetchStudies();
  }, []);

  useEffect(() => {
    // Clear messages when the selected study changes
    setMessages([]);
  }, [selectedStudyIds]);

  const fetchStudies = async () => {
    try {
      const response = await axios.get(`${API_URL}/v1/studies`);
      setStudies(response.data);
      if (response.data.length > 0) {
        // Update this line to select the first study by default
        setSelectedStudyIds([response.data[0].id]);
      }
    } catch (error) {
      console.error('Error fetching studies:', error);
    }
  };

  const getRandomTypingResponse = () => {
    return typingResponses[Math.floor(Math.random() * typingResponses.length)];
  };

  useEffect(() => {
    if (isTyping) {
      setTypingMessage(getRandomTypingResponse());

      const interval = setInterval(() => {
        setTypingMessage(getRandomTypingResponse());
      }, 3000);
      setTypingInterval(interval);
    } else {
      if (typingInterval) {
        clearInterval(typingInterval);
        setTypingInterval(null);
      }
    }

    return () => {
      if (typingInterval) {
        clearInterval(typingInterval);
      }
    };
  }, [isTyping]);

  const sendMessage = async (text, shouldSetMessages = true) => {
    if (!text) return;

    // Add user's message to the chat
    const userMessage = {
      position: 'right',
      type: 'text',
      text: text,
      date: new Date(),
      className: 'rce-mbox-right',
    };

    if (shouldSetMessages) {
      setMessages((prevMessages) => [...prevMessages, userMessage]);
    }

    setIsTyping(true);

    try {
      const response = await axios.post(`${API_URL}/v1/query`, {
        text,
        study_ids: selectedStudyIds, // Update this line to send multiple study IDs
      });

      const responseText = response.data.response;

      const apiMessage = {
        position: 'left',
        type: 'text',
        text: responseText,
        date: new Date(),
        className: 'rce-mbox-left',
        replyButton: true,
      };

      setMessages((prevMessages) => [...prevMessages, apiMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="chat-container">
        <nav className="navigation-bar">
          <div style={{ width: '33%' }}>
            <FormControl variant="filled">
              <InputLabel id="multiple-study-label">Select Studies</InputLabel>
              <Select
                labelId="multiple-study-label"
                id="multiple-study-select"
                multiple
                value={selectedStudyIds ?? []}
                onChange={(event) => setSelectedStudyIds(event.target.value)}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={studies.find(study => study.id === value)?.name} />
                    ))}
                  </Box>
                )}
              >
                {studies.map((study) => (
                  <MenuItem key={study.id} value={study.id}>
                    {study.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: '33%' }}>
            <p style={{ textAlign: 'center', marginBottom: 5 }}>Chat with</p> 
            <p style={{ textAlign: 'center', marginTop: 0 }}><strong>{selectedStudyIds.map(id => studies.find(study => study.id === id)?.name).join(', ')}</strong></p>
          </div>
          <div style={{ width: '33%' }} />
        </nav>
        <div className="messages">
          {messages.length === 0 ? (
            <div className="empty-state">Start a conversation</div>
          ) : (
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={messages}
              onReplyClick={(event) => {
                const messageIndex = messages.findIndex(i => i.text === event.text);
                const previousMessage = messages[messageIndex - 1].text;
                sendMessage(`I'm unhappy with the original results of this message. Can you please try to generate a new response? \n\nPrevious user message: ${previousMessage}. Previous AI result: ${event.text}`, false);
              }}
            />
          )}
          {isTyping && (
            <SystemMessage text={typingMessage} />
          )}
        </div>
        <div className="input">
          <ChatInput disabled={selectedStudyIds.length === 0} onSend={sendMessage} />
        </div>
      </div>
    </ThemeProvider>
  );
};

const ChatInput = ({ onSend, disabled }) => {
  const [inputText, setInputText] = useState('');

  const handleSend = () => {
    if (inputText.trim()) {
      onSend(inputText.trim());
      setInputText('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <Input
      placeholder="Type a message..."
      multiline={false}
      value={inputText}
      onChange={(e) => setInputText(e.target.value)}
      onKeyPress={handleKeyPress}
      disabled={disabled}
      rightButtons={
        <button className="send-button" onClick={handleSend} disabled={disabled}>
          Send
        </button>
      }
    />
  );
};

export default Chat;